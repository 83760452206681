h1 {
  color: #fff;
  font-size: 24px;
  margin: 10px;
}
p {
  color: #fff;
  font-size: 14px;
  line-height: 14px;
  margin: 10px;
}

div {
  font-size: 14px;
}
span {
  font-size: 14px;
}
.baseLists {
  color: #fff;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;

  display: flex;
}

.background {
  height: 100%;
  flex-direction: column;
  flex-basis: 100%;
  justify-content: space-evenly;
  align-items: stretch;
  padding-bottom: 15px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;

  display: flex;
}

.flex-container {
  width: 100%;
  flex-direction: row;
  flex-basis: 6%;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 26px;
  display: flex;
}
.checkbox-container {
  width: 100%;
  height: fit-content;
  flex-direction: row;
  justify-content: left;
  align-items: stretch;
  margin: 0px;
  padding: 0px;
  margin-top: 10px;
  display: flex;
}

.input-container {
  width: 100%;
  height: fit-content;
  flex-direction: row;
  justify-content: left;
  align-items: stretch;
  margin-top: 0px;
  padding: 0px;
  margin-top: 10px;
  display: flex;
}

body {
  background-color: #1e1e1e;
}
